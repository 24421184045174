@tailwind base;
@tailwind components;
@tailwind utilities;

.relay-url-container {
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  overflow: hidden;
}
.relay-url-container::before,
.relay-url-container::after {
  content: "";
  position: absolute;
  z-index: -1;
}

.relay-url-container::before {
  animation: 6s rotate-shine linear infinite;
  width: 100%;
  height: 1500%;
  background: var(--tile-border);
}

.relay-url-container::after {
  inset: 0;
  padding: 1px;
  background-clip: content-box;
}

@keyframes rotate-shine {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );
}

html.dark {
  --tile-start-rgb: 2, 13, 46;
  --tile-end-rgb: 2, 5, 19;
  --tile-border: conic-gradient(
    #ffffff80,
    #ffffff40,
    #ffffff30,
    #ffffff20,
    #ffffff10,
    #ffffff10,
    #ffffff80
  );
}
